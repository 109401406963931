.CalendarDay__default {
  border: 0;
}

.CalendarMonth_table {
  margin-top: 18px;
}

.DateInput__small {
  width: 80px;
}

.DayPicker_weekHeader_li {
  color: #3187b1;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
}

.CalendarMonth_caption {
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  color: #363636;
}

.CalendarDay__selected {
  background: #42afe4;
  border: 1px double #42afe4;
  color: #fff;
}

.CalendarDay__selected:hover {
  background: #42afe4;
  border: 1px double #42afe4;
  color: #fff;
}

.CalendarDay__selected:active {
  background: #42afe4;
  border: 1px double #42afe4;
  color: #fff;
}

.CalendarDay__selected_span {
  background: #d9effa;
  border: 1px double #42afe4;
  color: #363636;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #42afe4;
  border: 1px double #42afe4;
  color: #fff;
}

.DateInput_input__focused {
  border-bottom: 2px solid #42aee4;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: normal;
  font-weight: normal;
}

.DateInput_input {
  color: #363636;
  font-family: 'Asap';
  font-size: 12px;
  padding-bottom: 0;
  padding-top: 0;
  height: 22px;
}

.DateRangePickerInput .DateInput_input {
  font-size: 12px;
  padding-bottom: 0;
  padding-top: 0;
  height: 22px;
}

.DateRangePickerInput_arrow {
  vertical-align: top;
}

.SingleDatePicker {
  height: 25px;
}

.SingleDatePickerInput__withBorder {
  border-color: rgba(0, 0, 0, 0.23);
  height: 25px;
  border-radius: 4px;
  overflow: hidden;
}

.DateRangePickerInput__withBorder {
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  overflow: hidden;
}

.DateRangePicker {
  height: 25px;
}
